<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPanel.export_application') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.apply')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                    <b-row class="btb_license_input_label">
                    <!------------------- general information section ------------------->
                    <b-col sm="12">
                        <div class="group-form-card">
                            <b-card>
                                <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.general_information')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                    <b-row>
                                        <!-- broker company -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Broker Company" vid="btb_broker_license_id" :rules="{required: true, min_value: 1}">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="btb_broker_license_id">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenConfig.broker_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        :disabled="brokerListLoder ? true : false"
                                                        v-model="application.btb_broker_license_id"
                                                        id="btb_broker_license_id"
                                                        :options="brokerList"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{ brokerListLoder ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- registration No  -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Registration No" vid="broker_registration_no" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="broker_registration_no">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.registration_no') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        disabled
                                                        type="text"
                                                        v-model="application.broker_registration_no"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- tea quantity -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Tea Quantity (KG)" vid="tea_quantity" :rules="{required: true}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="tea_quantity">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.tea_quantity') }} ({{ $t('globalTrans.kg') }})<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        @keypress="isNumber"
                                                        v-model.number="application.tea_quantity"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- port names -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Port Name" vid="port_names_id" :rules="{required: true}">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="port_names_id">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.port_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <v-select
                                                        multiple
                                                        plain
                                                        v-model="application.port_names_id"
                                                        id="port_names_id"
                                                        :reduce="op => op.value"
                                                        :options="portNameList"
                                                        label="text"
                                                        :placeholder="$t('globalTrans.select')"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </v-select>
                                                    <div class="invalid-feedback d-flex">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- export country -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Export Country" vid="export_country_id" :rules="{required: true, min_value: 1}">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="export_country_id">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.export_country') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="application.export_country_id"
                                                        id="export_country_id"
                                                        :options="exportCountries"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- last_export_date -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Last Export Date" vid="last_export_date" :rules="{required: false}" v-slot="{ errors }">
                                            <b-form-group
                                                label-for="last_export_date">
                                                <template v-slot:label>
                                                {{ $t('teaGardenPanel.last_export_date') }}
                                                </template>
                                                <date-picker
                                                id="last_export_date"
                                                v-model="application.last_export_date"
                                                class="form-control"
                                                :placeholder="$t('globalTrans.select')"
                                                :locale="currentLocale"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </date-picker>
                                                <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- contract_date -->
                                        <b-col sm="6">
                                            <ValidationProvider name="Last Export Date" vid="contract_date" :rules="{required: false}" v-slot="{ errors }">
                                            <b-form-group
                                                label-for="contract_date">
                                                <template v-slot:label>
                                                {{ $t('teaGardenPanel.contract_date') }}
                                                </template>
                                                <date-picker
                                                id="contract_date"
                                                v-model="application.contract_date"
                                                class="form-control"
                                                :placeholder="$t('globalTrans.select')"
                                                :locale="currentLocale"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </date-picker>
                                                <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- tt_no_en -->
                                        <b-col sm="6">
                                            <ValidationProvider name="TT No" vid="tt_no_en" :rules="{required: false}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="tt_no_en">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.tt_no') }} {{ $t('globalTrans.en') }}
                                                    </template>
                                                    <b-form-input
                                                        v-model="application.tt_no_en"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- tt_no_bn -->
                                        <b-col sm="6">
                                            <ValidationProvider name="TT No" vid="tt_no_bn" :rules="{required: false}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="tt_no_bn">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.tt_no') }} {{ $t('globalTrans.bn') }}
                                                    </template>
                                                    <b-form-input
                                                        v-model="application.tt_no_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </b-card>
                        </div>
                    </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <!------------------- add more section -------------------->
            <b-col sm="12">
                <div class="group-form-card">
                    <b-card>
                        <b-form-group label-cols-lg="12" :label="$t('teaGardenPanel.addmore_section')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                            <b-col md="12">
                            <!------------------- add more start -------------------->
                            <ValidationObserver ref="add_more">
                                <b-overlay :show="addMoreLoding">
                                <b-row>
                                    <!-- sell no -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Sell No" vid="sell_no" :rules="{required: true}">
                                                <b-form-group label-for="sell_no" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.sell_no') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="sell_no"
                                                    v-model="addMoreData.sell_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- garden name -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Garden Name" vid="garden_id" :rules="{required: true, min_value: 1}">
                                                <b-form-group label-for="garden_id" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenConfig.garden_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <v-select
                                                        plain
                                                        v-model="addMoreData.garden_id"
                                                        id="garden_id"
                                                        :reduce="op => op.value"
                                                        :options="getGardenList"
                                                        label="text"
                                                        :placeholder="$t('globalTrans.select')"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </v-select>
                                                    <div class="invalid-feedback d-block text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- lot not -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Lot No" vid="lot_no" :rules="{required: true}">
                                                <b-form-group label-for="lot_no" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.lot_no') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="lot_no"
                                                    v-model="addMoreData.lot_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- tea grade name -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Made Tea Grade" vid="made_tea_grage_id" :rules="{required: true, min_value: 1}">
                                                <b-form-group label-for="made_tea_grage_id" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenConfig.grade_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        id="made_tea_grage_id"
                                                        v-model="addMoreData.made_tea_grage_id"
                                                        :options="madeTeaGardeList"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback d-block text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- package -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Package" vid="package" :rules="{required: true}">
                                                <b-form-group label-for="package" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.package') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="package"
                                                    @keypress="isNumber"
                                                    v-model.number="addMoreData.package"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- weight -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Weight" vid="weight" :rules="{required: true}">
                                                <b-form-group label-for="weight" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.weight') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    @keypress="isNumber"
                                                    id="weight"
                                                    v-model.number="addMoreData.weight"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- auction price -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Auction Price" vid="auction_price" :rules="{required: true}">
                                                <b-form-group label-for="auction_price" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.auction_price') }} ({{ $t('globalTrans.tk_sign') }}) <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    @keypress="isNumber"
                                                    id="auction_price"
                                                    v-model.number="addMoreData.auction_price"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- export price -->
                                        <b-col sm="3">
                                            <ValidationProvider name="Export Price" vid="export_price" :rules="{required: true}">
                                                <b-form-group label-for="export_price" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.export_price') }} ({{ $t('globalTrans.tk_sign') }})<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    @keypress="isNumber"
                                                    id="export_price"
                                                    v-model.number="addMoreData.export_price"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback text-left">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    <!-- action -->
                                        <div class="text-right my-2">
                                            <!-- cencel button -->
                                            <b-button type="button" v-if="editItemIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem">{{ $t('globalTrans.cancel') }}</b-button>
                                            <!-- add button -->
                                            <b-button type="button" @click="addMoreItem" variant="success" class="btn-sm"> <i class="ri-add-circle-line"></i>{{ editItemIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add_more')}}</b-button>
                                        </div>
                                </b-row>
                                </b-overlay>
                            </ValidationObserver>
                            <!------------------- add more end -------------------->
                            <!------------------ add more data show start ---------------->
                            <b-table-simple hover small bordered>
                                <b-thead>
                                <b-tr>
                                    <b-th>{{ $t('teaGardenPanel.sell_no') }}</b-th>
                                    <b-th>{{ $t('teaGardenConfig.garden_name') }}</b-th>
                                    <b-th>{{ $t('teaGardenPanel.lot_no') }}</b-th>
                                    <b-th>{{ $t('teaGardenConfig.grade_name') }}</b-th>
                                    <b-th>{{ $t('teaGardenPanel.package') }}</b-th>
                                    <b-th>{{ $t('teaGardenPanel.weight') }} ({{ $t('globalTrans.kg') }})</b-th>
                                    <b-th>{{ $t('teaGardenPanel.auction_price') }} ({{ $t('globalTrans.tk_sign') }})</b-th>
                                    <b-th>{{ $t('teaGardenPanel.export_price') }} ({{ $t('globalTrans.tk_sign') }})</b-th>
                                    <b-th>{{ $t('globalTrans.action') }}</b-th>
                                </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <template v-if="application.details && Object.keys(application.details).length > 0">
                                        <b-tr v-for="(item, index) in application.details" :key="index">
                                            <!-- sell no -->
                                            <b-td>
                                                <b-col>
                                                {{ item.sell_no }}
                                                </b-col>
                                            </b-td>
                                            <!-- garden name -->
                                            <b-td>
                                                {{ getGardenName(item.garden_id) }}
                                            </b-td>
                                            <!-- lot not -->
                                            <b-td>
                                                {{ item.lot_no }}
                                            </b-td>
                                            <!-- tea grade name -->
                                            <b-td>
                                                {{ getTeaGradeName(item.made_tea_grage_id) }}
                                            </b-td>
                                            <!-- package -->
                                            <b-td>
                                                {{ $n(item.package) }}
                                            </b-td>
                                            <!-- weight -->
                                            <b-td>
                                            {{ $n(item.weight) }}
                                            </b-td>
                                            <!-- auction price -->
                                            <b-td>
                                                {{ $n(item.auction_price) }}
                                            </b-td>
                                            <!-- export price -->
                                            <b-td>
                                                {{ $n(item.export_price) }}
                                            </b-td>
                                            <!-- action -->
                                            <b-td style="width: 12%;">
                                                <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                                <b-button variant="action-btn success" size="sm" :title="$t('globalTrans.edit')" @click="editItem(item, index)" class="action-btn edit"><i class="ri-pencil-fill"></i></b-button>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <td colspan="5">{{ $t('globalTrans.total') }}</td>
                                            <td>{{ $n(totalWeight) }}</td>
                                            <td>{{ $n(totalAuctionPrice) }}</td>
                                            <td>{{ $n(totalExportPrice) }}</td>
                                            <td></td>
                                        </b-tr>
                                    </template>
                                    <template v-else>
                                        <b-tr>
                                            <b-td colspan="9" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                    </template>
                                </b-tbody>
                            </b-table-simple>
                            <!------------------ add more data show end ---------------->
                            </b-col>
                        </b-form-group>
                    </b-card>
                </div>
            </b-col>
            <!------------------- submit buttons ------------------->
            <b-row class="text-right mb-3">
            <b-col>
                <b-button type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                <b-button type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="back()">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
            </b-row>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>
// import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '@/components/BreadCumb.vue'
import { mapGetters } from 'vuex'
import { helpers } from '@/utils/helper-functions'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { exportLicenseApplicationStoreApi, exportLicenseApplicationUpdateApi, exportLicenseBrokerListApi } from '../../api/routes'
export default {
  components: { BreadCumb },
  name: 'Form',
  data () {
    return {
        baseUrl: teaGardenServiceBaseUrl,
        valid: null,
        saveBtnName: this.$t('globalTrans.update'),
        errors: [],
        isRenew: false,
        application: {
            service_id: 35,
            user_id: 0,
            application_type: 1,
            btb_broker_license_id: 0,
            export_country_id: 0,
            port_names_id: [],
            total_auction_price: 0,
            total_export_price: 0,
            details: []
        },
        addMoreData: {
            garden_id: '',
            made_tea_grage_id: 0
        },
        addMoreLoding: false,
        editItemIndex: '',
        brokerList: [],
        brokerListLoder: false,
        draft: 1,
        isLoading: false
    }
  },
  created () {
    this.getBrokerList()
    if (this.$route.query.id) {
        this.isLoading = true
        this.getData()
        this.application.application_type = this.application.application.application_type
        this.isLoading = false
    } else {
        this.isLoading = false
    }
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    loading: function () {
        return this.$store.state.commonObj.loading
    },
    ...mapGetters({
        commonProfile: 'Auth/commonProfile',
        authUser: 'Auth/authUser'
    }),
    id () {
        return this.$route.query.id
    },
    exportCountries () {
      return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1)
    },
    portNameList () {
        return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'চট্টগ্রাম নৌ বন্দর' : 'Chattogram Sea Port' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'মংলা নৌ বন্দর' : 'Mongla Sea Port' },
        { value: 3, text: this.$i18n.locale === 'bn' ? 'হযরত শাহজালাল আন্তর্জাতিক বিমান বন্দর' : 'Hazrat Shahjalal International Airport' },
        { value: 4, text: this.$i18n.locale === 'bn' ? 'শাহ্ আমানাত আন্তর্জাতিক বিমান বন্দর' : 'Shah Amanat International Airport' },
        { value: 5, text: this.$i18n.locale === 'bn' ? 'ওসমানী আন্তর্জাতিক বিমান বন্দর' : 'Osmani International Airport' },
        { value: 6, text: this.$i18n.locale === 'bn' ? 'কক্সবাজার আন্তর্জাতিক বিমান বন্দর' : "Cox's Bazar International Airport" }
        ]
    },
    madeTeaGardeList () {
      if (this.authUser.user_type === 2) {
        return this.$store.state.ExternalUserService.teaGarden.commonObj.masterMadeTeaGradeList.filter(item => item.status === 1)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterMadeTeaGradeList.filter(item => item.status === 1)
      }
    },
    getGardenList () {
      if (this.authUser.user_type === 2) {
        return this.$store.state.ExternalUserService.teaGarden.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
      }
    },
    totalAuctionPrice () {
        let total = 0
        this.application.details.forEach(item => {
            total += item.auction_price
        })
        return total
    },
    totalExportPrice () {
        let total = 0
        this.application.details.forEach(item => {
            total += item.export_price
        })
        return total
    },
    totalWeight () {
        let total = 0
        this.application.details.forEach(item => {
            total += item.weight
        })
        return total
    }
  },
  methods: {
    back () {
        return this.$router.go(-1)
    },
    async getBrokerList () {
        this.brokerListLoder = true
        let result = ''
        result = await RestApi.getData(teaGardenServiceBaseUrl, exportLicenseBrokerListApi)

        if (result.success) {
            this.brokerList = result.data.map(item => {
                return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
            })
        } else {
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: result.message,
                color: '#ee5253'
            })
        }

        this.brokerListLoder = false
    },
    async getData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
        const tmp = JSON.parse(JSON.stringify(tmpData))
        this.application = Object.assign({}, tmp, { application_type: 1, service_id: tmp.application.service_id })
    },
    async saveUpdate (status = 1) {
        var check = await this.$refs.form.validate()
        this.application.status = status
        if (check) {
            // check add more item not empty
            if (this.application.details.length === 0) {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('globalTrans.add_more_item'),
                    color: '#ee5253'
                })
                return false
            }

            // check total tea quantity and item weight same
            if (!this.checkTotalWeight()) {
                return false
            }

            let result = ''
            this.isLoading = true

            if (this.id) {
                result = await RestApi.putData(teaGardenServiceBaseUrl, `${exportLicenseApplicationUpdateApi}/${this.id}`, this.application)
            } else {
                result = await RestApi.postData(teaGardenServiceBaseUrl, exportLicenseApplicationStoreApi, this.application)
            }

            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

            if (result.is_payment) {
                    const baseTableId = result.base_table_id
                    if (this.authUser.user_type === 2) {
                        this.$router.push({ name: 'tea_garden_panel.payment', params: { id: baseTableId }, query: { from: 'tea_garden_panel.export_license_application' } })
                    } else {
                        this.$router.push({ name: 'tea_garden_service.btb.payment', params: { id: baseTableId }, query: { from: 'tea_garden_service.btb.export_license_application' } })
                    }
                } else {
                    if (this.authUser.user_type === 2) {
                        this.$router.push({ name: 'tea_garden_panel.export_license_application' })
                    } else {
                        this.$router.push({ name: 'tea_garden_service.btb.export_license_application' })
                    }
                }
            } else {
                if (!result.match) {
                    this.isLoading = false

                    this.$toast.error({
                      title: this.$t('globalTrans.error'),
                      message: result.message,
                      color: '#ee5253'
                    })
                }

                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: this.$t('globalTrans.form_error_msg'),
                color: '#ee5253'
                })
            }
            this.isLoading = false
        }
    },
    checkTotalWeight () {
        if (this.application.tea_quantity !== this.totalWeight) {
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: this.$t('teaGardenPanel.teq_quantity_not_match'),
                color: '#ee5253'
            })
            return false
        }
        return true
    },
    isNumber (evt) {
      helpers.isNumber(evt)
    },
    async addMoreItem () {
        this.addMoreLoding = true
        const isValid = await this.$refs.add_more.validate()
        if (isValid) {
            if (parseInt(this.editItemIndex) >= 0) {
                this.application.details[parseInt(this.editItemIndex)] = Object.assign({}, this.addMoreData)
                // for calculation computed property. when any object property value update computed not sync. that why i have used this assign process
                var datas = [...this.application.details]
                this.application.details = datas
            } else {
                this.application.details.push(this.addMoreData)
            }

            this.addMoreData = {
                garden_id: '',
                made_tea_grage_id: 0
            }
            this.editItemIndex = ''
            this.$refs.add_more.reset()
        }
        this.addMoreLoding = false
    },
    removeItem (index) {
        this.application.details.splice(index, 1)
    },
    editItem (item, index) {
        this.addMoreData = Object.assign({}, item)
        this.editItemIndex = index
    },
    cancelItem () {
        this.addMoreData = {}
        this.editItemIndex = ''
        this.$refs.add_more.reset()
    },
    getTeaGradeName (id) {
        const obj = this.madeTeaGardeList.find(item => item.value === id)
        if (obj) {
            return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
        }
    },
    getGardenName (id) {
        const obj = this.getGardenList.find(item => item.value === id)
        if (obj) {
            return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
        }
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.getBrokerList()
        }
    },
    'application.btb_broker_license_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            const obj = this.brokerList.find(item => item.value === newVal)
            if (obj) {
                this.$set(this.application, 'broker_registration_no', obj?.registration_no)
                this.$set(this.application, 'applicant_name_en', obj?.applicant_name_en)
                this.$set(this.application, 'applicant_name_bn', obj?.applicant_name_bn)
                this.$set(this.application, 'company_name_en', obj?.text_en)
                this.$set(this.application, 'company_name_bn', obj?.text_bn)
                this.$set(this.application, 'user_id', obj?.user_id)
            }
        }

        if (!newVal) {
            this.application.broker_registration_no = ''
            this.application.applicant_name_en = ''
            this.application.applicant_name_bn = ''
            this.application.company_name_en = ''
            this.application.company_name_bn = ''
        }
    }
   }
}
</script>
